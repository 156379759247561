* {
  margin: 0;
}

.restName {
  height: 43px;
  font-family: Poppins;
  font-size: 30px;
  color: #192f60;
}

.cuisines {
  height: 10px;
  font-size: 0.9rem;
  color: #2d0b4d;
  min-height: 1.3rem;
 line-height: 1em;;
}

.cuisines1 {
  height: 20px;
  font-family: Poppins;
  font-size: 1rem;
  color: #88111b;
}

.qtty {
  width: 2.5rem;
  text-align: center;
  font-weight: bold;
  color: #000;
}

.tglDelivery.active {
   color: white; 
  background: hsl(224, 79%, 46%); 
}

span {
  color: #000;
}