.heading {
    height: 43px;
    font-family: Poppins;
    font-size: 30px;
    color: #192f60;
}

.subheading {
    height: 25px;
    font-size: 18px;
    color: #192f60 !important;
}

.bighead {
    height: 43px;
    font-family: Poppins;
    font-size: 29px;
    color: #192f60;
}

.card{
    border-radius: 0%;
    box-shadow: 0px 3px 3px 0px #dee2e6;
    margin-bottom: 10px;
    border-color: #fff;
}

.card-text{
    color: #8c96ab;
    font-size: 20px;
}

img{
 padding: 5%;
}

.res-info{
    padding: 2%;
}