/********************* BUTTONS *****************************/
.login {
  font-size: larger;
  font-weight: bold;
  color: #fff;
}

.createacc {
  font-size: larger;
  background-color: #807575;
  color: #fff;
  font-weight: bold;
  border: solid 1px #fff;
  border-radius: 3px;
}

/***************** SUGGESSTION SECTION *************************/
.suggestionsBox {
  background: rgb(58, 12, 12);
  list-style: none;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

.suggestionItem {
  display: flex;
  align-content: center;
  align-items: center;
  width: auto;
  padding: 0.7rem;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  background-color: #ffffff;
}

.suggestionImage {
  display: inline-block;
}

.suggestionImage img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.suggestionText {
  margin-left: 1rem;
}

.suggestionTextName {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: bold;
  color: #192f60;
}

.suggestionTextLocality {
  height: 1rem;
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2;
  letter-spacing: normal;
  text-align: left;
  color: #636f88;
}

.orderButton {
  margin-left: 15%;
  padding: 5px;
  color: #f00;
  font-weight: bold;
  border-radius: 15px;
  border: 1px solid #ff0000;
}

.orderButton:hover{
  background-color: #ff0000;
  color: #fff;
}

/************ FOR TABLETS **********/
@media only screen and (max-width: 900px) {
  .body {
    display: none;
  }
}

/************ FOR PHONES **********/
@media only screen and (max-width: 500px) {
  body {
    background: #4f0707;
  }
  .bigtext {
    font-size: 0.8rem;
  }

  .logo {
    margin-left: 0.2rem;
  }
}

/* ---------------------------------------------- */
.newbanner {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background: linear-gradient(to bottom, rgba(157, 14, 14, 0), #4f0707),
    url('../Banner1.png');
  background-position: center;
  background-size: cover;
  position: relative;
  width: auto;
  /* height: 76vh; */
}

.banner__accounts {
  display: flex;
  /* align-items: center; */
  /* gap: 0.5rem; */
  justify-content: flex-end;
  padding: 2rem;
  top: 2rem;
  right: 2rem;
}

.banner__logo {
  display: inherit;
  justify-content: center;
  padding: 0% !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.banner__text {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Poppins;
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.banner__form {
  display: flex;
  gap: 3rem;
  margin-bottom: 2rem;
}

.banner__form__left {
  flex: 0.2;
  width: auto;
  height: 10vh;
  background-color: #fff;
}

.banner__form__right {
  width: 500px;
  height: 10vh;
  /* flex: 0.8; */
}

@media only screen and (max-width: 900px) {
  .banner__accounts {
    padding: 2rem;
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  .banner__accounts {
    justify-content: center;
  }

  .banner__text {
    text-align: center;
  }

  .banner__form__right {
    width: auto;
    height: 10vh;
  }
  .banner__form {
    flex-direction: column;
    gap: 1rem;
  }
}
