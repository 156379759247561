/************************ SAME FOR LOGIN FORM ***************************/
.Super1 {
  text-align: center;
  font-weight: bold;
  font-style: italic;
}

.Login {
  color: red;
}

/*************************** FOR DIVIDING THE FORMS *******************************/
.Path {
  height: 0;
  margin: 20px 8.8px 8px 0;
  border: solid 1px #c7c7c7;
}

.haveaccount {
  margin: 10px 3px 10px 46px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #192f60;
  cursor: pointer;
}

form {
  padding: 1rem;
  background-color: whitesmoke;
  border-radius: 3ren;
  font-size: 12px;
}

form h5 {
  color: #0f2027;
  text-align: center;
  text-decoration: underline;
}

form button {
  padding: 4px;
  width: 100%;
  color: white;
  background-color: rgb(41, 57, 194);
  border: none;
  border-radius: 4px;
}


::placeholder{
    font-size: 14px;
}

form button{
    background: #141a34;
    color: #fff;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding: 10px 40px;
    outline: 0;
    cursor: pointer;
    display: block;
    margin: 30px auto 10px;
}

