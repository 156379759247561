.elogo{
    /* background-color: white; */
    width: 40px;
    height: 40px;
    /* border-radius: 50%; */
    color: #fff;
    font-family: poppins;
    font-size: 26px;
    padding-left: 15%;
    left: 3rem;
    position: relative;
}

.dropdown {
    right: 3rem;
    top: 0.4rem;
}

.avatar {
    position: relative;
    right: 2rem;
}